<template>
    <div v-if="word" ref="doc" class="docment animate__animated animate__fadeInUp">
        <div class="left">
            <div style="background-color: #fff">
                <div v-for="item in list" :key="item.id" :class="{'active': active == item.id, 'last' : (active - 1) == item.id}" @click="jump(item)"><span>{{item.name}}</span></div>
            </div>
        </div>
        <div class="right" v-if="show">
            <div class="right-title">{{detail.name}}</div>
            <div v-if="detail.tips" class="right-alone">{{detail.tips}}</div>
            <div v-if="detail.method" class="right-subtitle">{{word.docment.request_method[language]}}</div>
            <div v-if="detail.method" class="right-alone">HTTP {{detail.method}}</div>
            <div v-if="detail.url" class="right-subtitle">{{word.docment.request_url[language]}}</div>
            <div v-if="detail.url">{{detail.url}}</div>
            <div v-if="detail.para" class="right-subtitle">{{word.docment.request_body[language]}}</div>
            <div v-if="detail.requestExample">{{word.docment.request_example[language]}}</div>
            <div v-if="detail.requestExample" class="example">
                <pre style="white-space: pre-wrap">{{detail.requestExample}}</pre>
            </div>
            <div v-if="detail.para" class="right-table">
                <table class="request">
                    <thead>
                        <tr>
                            <th>{{word.docment.name[language]}}</th>
                            <th>{{word.docment.meaning[language]}}</th>
                            <th>{{word.docment.discription[language]}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(para, index) in detail.para" :key="index">
                            <td>{{para.name}}</td>
                            <td>{{para.mean}}</td>
                            <td>{{para.declare}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="detail.receive" class="right-subtitle">{{word.docment.return_body[language]}}</div>
            <div v-if="detail.receive">{{word.docment.return_example[language]}}</div>
            <div v-if="detail.receiveExample" class="example">
                <div v-if="(active < 4 || active == 7) && language == 'ID'" class="remark"><pre>//   Kesuksesan</pre></div>
                <pre>{{detail.receiveExample}}</pre>
            </div>
            <div v-if="detail.receive" class="right-table">
                <table class="response">
                    <thead>
                        <tr>
                            <th>{{word.docment.field[language]}}</th>
                            <th>{{word.docment.discription[language]}}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(receive, index) in detail.receive" :key="index">
                            <td>{{receive.name}}</td>
                            <td>{{receive.declare}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-if="detail.remark" class="right-table">
                <table class="response">
                    <thead>
                        <tr>
                            <th>code</th>
                            <th>message</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(remark, index) in detail.remark" :key="index">
                            <td>{{remark.name}}</td>
                            <td>{{remark.declare}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import 'animate.css';
import axios from '@/utils/request/request.js'
import base from '@/utils/request/base.js'
import word from './../assets/json/language.json'
import docment from './../assets/json/docment.json'
export default {
    name: 'Docment',
    components: {},
    data() {
        return {
            language: '',
            word: word,
            list: [],
            active: 1,
            detail: {},
            show: true
        }
    },
    created() {
        this.language = localStorage.getItem('language')
        this.list = docment[this.language];
        if(this.$route.query && this.$route.query.id) {
            for(let item of this.list) {
                if(item.id == this.$route.query.id) {
                    this.toggle(item, 100)
                }
            }
        }else {
            this.detail = this.list[0]
        }
    },
    methods: {
        jump(item) {
            this.$router.push({ path: '/docment', query: { id: item.id }})
            this.toggle(item)
        },
        toggle(item, time) {
            this.show = false;
            setTimeout(()=>{
                this.active = item.id;
                this.detail = item;
                this.show = true;
                console.log(this.$refs.doc.offsetTop)
                document.documentElement.scrollTop = this.$refs.doc.offsetTop - 80;
            }, time ? time : 0)
        }
    }
}
</script>
<style lang="scss" scoped>
    .docment {
        display: flex;
        .left {
            flex-shrink: 0;
            width: 20%;
            text-align: center;
            background-color: #F5F5F6;
            >div {
                position: sticky;
                top: 80px;
                z-index: 100;
                >div {
                    background-color: #F5F5F6;
                    padding: 20px;
                    cursor: pointer;
                    &.active {
                        background-color: #fff;
                        color: #108CCF;
                        position: relative;
                    }
                    &.active+div {
                        border-top-right-radius: 16px;
                    }
                    &.last {
                        border-bottom-right-radius: 16px;
                    }
                }
            }
        }
        .right {
            width: 75%;
            >div {
                width: 70%;
                margin-bottom: 20px;
                word-break: break-all;
            }
            pre {
                font-size: 16px;
            }
            padding-left: 5%;
            .right-title {
                margin: 40px 0 20px;
                font-size: 40px;
            }
            .right-subtitle {
                font-size: 20px;
                color: #108CCF;
                margin: 40px 0 20px;
            }
            .right-alone {
                margin: 20px 0;
            }
            .right-table {
                border-radius: 8px;
                background-color: #EBEBEB;
                width: 70%;
                padding: 20px;
                overflow-x:auto;
                max-width:100%;
                table {
                    display: table;
                    width: 600px;
                    border-collapse: collapse;
                    border-spacing: 0;
                    overflow: auto;
                    font-size: 14px;
                    &.request {
                        th {
                            width: 30%;
                            &:last-child {
                                width: 40%;
                            }
                        }
                    }
                    &.response {
                        th {
                            width: 40%;
                            &:first-child {
                                width: 30%;
                            }
                        }
                    }
                    th {
                        width: 20%;
                        font-weight: 600;
                        text-align: left;
                        border-bottom: 1px solid #888;
                        padding: 10px 0;
                        white-space: nowrap;
                        color: #888;
                    }
                    tbody {
                        tr {
                            td {
                                padding: 20px 0;
                            }
                        }
                    }
                }
            }
        }
    }
    .example {
        position: relative;
        background-color: #F6F8FE;
        padding: 10px 20px;
        border-radius: 16px;
        .remark {
            position: absolute;
            top: 46px;
            left: 230px;
            color: #999;
        }
    }
</style>
